import React from 'react';
import 'boxicons/css/boxicons.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/Home';

function App() {
  return (
    <Router>
      <div className="bg-[#131A25] flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow pt-24 lg:pt-16">
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;