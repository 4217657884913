import React, { useRef, useState } from 'react';
import logo from '../image/Logo.png';


const Welcome = () => {
    const inputRef = useRef(null);
    const [copyMessage, setCopyMessage] = useState('');

    const handleCopy = () => {
        // Selecciona el texto dentro del input  
        inputRef.current.select(0x896ff3bdab3a3067e21fe30d77cbad9fcbe0b3ec);
        // Copia el texto seleccionado al portapapeles  
        document.execCommand('copy');

        // Actualiza el mensaje y lo elimina después de 2 segundos  
        setCopyMessage('Texto copiado');
        setTimeout(() => {
            setCopyMessage('');
        }, 2000);
    };

    return (
        <div className='flex flex-col items-center lg:flex-row py-8 lg:px-60 justify-center gap-4'>
            <div className='flex flex-col items-center lg:items-start justify-center gap-16 lg:w-7/12'>
                <h1 className='flex flex-col pt-8 lg:pt-0'>
                    <text className='text-xl text-amber-400 lg:text-5xl uppercase'>Bienvenido a</text>
                    <text className='text-2xl  text-white lg:text-7xl font-bold uppercase'>RenaceCoin</text>
                    <text className='text-3xl text-white lg:text-8xl font-bold uppercase'>más que un token</text>
                </h1>
                <div className='flex flex-col gap-4 lg:w-[600px]'>
                    <p className='text-white text-center lg:text-2xl lg:text-left'>
                        En un mundo donde la tecnología y la compasión pueden unirse, RenaceCoin se posiciona como una solución innovadora que canaliza recursos hacia organizaciones de rescate y cuidado animal. Únete a nosotros en esta misión para crear un futuro donde cada mascota tenga la oportunidad de ser amada y respetada.
                    </p>
                    <div className="flex items-center justify-center lg:justify-start ">
                        <input
                            type="text"
                            ref={inputRef}
                            Value="0x896ff3bdab3a3067e21fe30d77cbad9fcbe0b3ec"
                            readOnly
                            className="rounded-l-lg text-black p-2 bg-gray-200 lg:w-[400px]"
                        />
                        <button
                            onClick={handleCopy}
                            className="bg-amber-400 text-white p-2 rounded-r-lg"
                        >
                            Copiar
                        </button>
                        {copyMessage && (
                            <div className="copy-message ml-4 animate animate-fade-right text-white">{copyMessage}</div>
                        )}
                    </div>

                </div>
            </div>
            <div className='w-5/12'>
                <img src={logo} alt="Logo" className="w-full" />
            </div>
        </div >
    )
};

export default Welcome;