import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faTelegram,
    faLinkedin,
    faDiscord,
    faGithub,
    faYoutube,
    faTiktok
} from '@fortawesome/free-brands-svg-icons'; // Asegúrate de que estos íconos están disponibles en la versión que usas  

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'; // Importa faEnvelope desde los íconos sólidos


const Footer = () => {
    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <footer className="bg-[#292929] text-white py-4 mt-10">
            <div className="flex flex-col p-4 gap-4 mx-auto text-center">
                <h2 className='text-3xl text-amber-400'>
                    ¡Síganos!
                </h2>
                <p className='text-[#798DA3]'>
                    Manténgase actualizado con RenaceCoin. Únase a nosotros en nuestro viaje para revolucionar el mundo de las criptomonedas con inteligencia artificial, blockchain y el metaverso.
                </p>
                <nav>
                    <ul className="grid grid-cols-2 lg:flex lg:flex-row items-center text-white md:flex-row justify-center space-y-2 md:space-y-0 md:space-x-4">
                        <li>
                            <button onClick={() => scrollToSection('articulo1')} className="hover:text-amber-500">
                                <Link to="/" className="hover:text-amber-500">Inicio</Link>
                            </button>
                        </li>
                        <li>
                            <button onClick={() => scrollToSection('articulo2')} className="hover:text-amber-500">Vision</button>
                        </li>
                        <li>
                            <button onClick={() => scrollToSection('articulo3')} className="hover:text-amber-500">Potencial</button>
                        </li>
                        <li>
                            <button onClick={() => scrollToSection('articulo4')} className="hover:text-amber-500">Acerca de RNC</button>
                        </li>
                    </ul>
                </nav>
                <div className="flex justify-center space-x-4">
                    <a href="https://www.facebook.com/profile.php?id=61555617128819" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="https://twitter.com/RenaceCoin" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="https://www.instagram.com/renacecoin/" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://t.me/+b5DLKBZUA-wxYTkx" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                        <FontAwesomeIcon icon={faTelegram} />
                    </a>
                    <a href="https://www.linkedin.com/in/renacecoin-rnc-3aba3b2b2/" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                        <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a href="https://discord.gg/pKM9kjTy" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                        <FontAwesomeIcon icon={faDiscord} />
                    </a>
                    <a href="https://github.com/RENACECOIN/tokenrenacecoin.sol.git" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                        <FontAwesomeIcon icon={faGithub} />
                    </a>
                    <a href="https://www.youtube.com/@RenaceCoin" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                        <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    <a href="https://www.tiktok.com/@renacecoin?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                        <FontAwesomeIcon icon={faTiktok} />
                    </a>

                    <a href="mailto:rnc@renacecoin.org" target="_blank" rel="noopener noreferrer" className="flex items-center hover:text-amber-500">
                        <FontAwesomeIcon icon={faEnvelope} />
                    </a>

                </div>
                <p className="mb-2">© 2024 RenaceCoin. Todos los derechos reservados.</p>
            </div>
        </footer>
    );
};

export default Footer;