import React from "react";
import "../index.css";
import Welcome from "../screen/Welcome";
import Vision from "../screen/Vision";
// import Potencial from '../screen/Potencial';
import About from "../screen/About";
import BuyToken from "../screen/BuyToken";
import TokEconomics from "../screen/TokEconomics";
import Roadmap from "../screen/Roadmap";
import ContactUs from "../screen/ContactUs";
import FAQs from "../screen/FAQ";

const Home = () => {
  return (
    <div>
      <div
        id="articulo1"
        className="py-8 lg:pt-20 bg-articulo1 bg-cover bg-center flex items-center justify-center"
      >
        <Welcome />
      </div>
      <div
        id="articulo2"
        className="py-8 lg:pt-40  flex items-center justify-center"
      >
        <About />
      </div>
      <div
        id="articulo3"
        className="py-8 lg:pt-20 bg-articulo1 bg-cover  flex items-center justify-center"
      >
        <Vision />
      </div>
      <div
        id="articulo6"
        className="py-8 lg:pt-20 bg-articulo2 bg-cover flex items-center justify-center"
      >
        <TokEconomics />
      </div>

      <div id="articulo5" className="py-40  flex items-center justify-center">
        <BuyToken />
      </div>

      <div id="articulo7" className=" flex items-center justify-center">
        <Roadmap />
      </div>
      <div id="articulo4" className="my-36  flex items-center justify-center">
        <FAQs />
      </div>
      <div id="articulo8" className="py-8  flex items-center justify-center">
        <ContactUs />
      </div>
    </div>
  );
};

export default Home;
