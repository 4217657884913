import React from 'react';
import RoadmapCarrousel from '../components/RoadmapCarousel';


const Roadmap = () => {
    return (
        <div className="p-4 lg:px-60 w-full">
            <h1 className="text-xl lg:text-4xl text-white text-center my-8 uppercase font-bold">Hoja de Ruta de RenaceCoin</h1>
            <p className="lg:text-xl text-[#798DA3] text-center my-8 uppercase font-bold">
                RenaceCoin está diseñado para asegurar un crecimiento sostenible y un impacto positivo en el bienestar de las mascotas en situación de calle. Cada fase está enfocada en construir una comunidad activa, mejorar la plataforma y expandir las colaboraciones, todo mientras se mantiene un compromiso con la transparencia y la participación comunitaria.
            </p>
            <RoadmapCarrousel />

        </div>
    );
};

export default Roadmap;