import React from 'react';
import logo from '../image/Logo.png';


const Vision = () => {
    return (
        <div className='flex flex-col gap-8 w-full p-4 lg:px-60 lg:py-8'>
            <h2 className='flex flex-col items-center gap-2 p-2 '>
                <text className='text-xl lg:text-2xl text-amber-400 font-bold uppercase'>Descubra</text>
                <text className='text-2xl lg:text-4xl text-white font-bold uppercase'>la visión y mision de</text>
                <text className='text-3xl lg:text-4xl text-amber-400 font-bold uppercase'>RNC</text>
            </h2>


            <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                <div className='flex items-start'>
                    <div className=' flex flex-col gap-4 p-2 text-white'>
                        <h3 className='text-2xl font-bold uppercase'>visión</h3>
                        RenaceCoin se erige como un catalizador para la creación de comunidades más compasivas, donde la ayuda a las mascotas en situación de calle no sea solo un sueño, sino una realidad palpable. Buscamos un mundo donde cada transacción de RenaceCoin contribuya significativamente al bienestar animal y proporcione una plataforma sostenible para el cambio                    </div>
                </div>
                <div className='hidden lg:block gap-4 flex flex-col'>
                    <div className='flex justify-center'>
                        <img src={logo} alt="Logo" className="h-60" />
                    </div>
                </div>
                <div className='flex items-center'>
                    <div className='flex flex-col gap-4 p-2 text-white'>
                        <h3 className='text-2xl font-bold '>MISION</h3>
                        La misión de RenaceCoin es clara y contundente: utilizar la tecnología blockchain y la generosidad de nuestra comunidad para ofrecer una voz y recursos a aquellos seres indefensos que comparten nuestro planeta. Nos comprometemos a canalizar fondos de manera eficiente y transparente hacia organizaciones dedicadas al rescate, atención médica y reubicación de mascotas en situación de calle. A través de RenaceCoin, buscamos concientizar y movilizar a la sociedad para que se una a nuestra causa, marcando la diferencia en la vida de cada animal desamparado. Nuestra misión es crear un impacto real, tangible y sostenible en la lucha contra el abandono y el sufrimiento animal. RenaceCoin se esfuerza por ser más que un token;                    </div>
                </div>
            </div>
        </div>
    )
};

export default Vision;