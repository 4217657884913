import React from 'react';
import PieChart from '../components/PierChart';
import PDF from '../image/RenaceCoin.pdf';

const TokEconomics = () => {
    return (
        <div className='flex flex-col items-center justify-center'>
            <div className='py-16 px-4 flex flex-col-reverse lg:flex-row items-center lg:px-60'>
                <div className='flex justify-center lg:w-2/3'>
                    {/* <img src={logo} alt="Logo" className="h-80 lg:h-96" /> */}
                    <PieChart />
                </div>
                <div className='w-full lg:w-1/3 flex flex-col gap-4'>
                    <text className='text-xl flex justify-center lg:justify-start lg:text-5xl text-white uppercase font-bold' >
                        Tokenomics
                    </text>
                    <text className='text-xl flex justify-center lg:justify-start lg:text-5xl text-amber-400 uppercase font-bold' >
                        Oferta Total: 200,000,000 RNC.
                    </text>
                    <div className='text-[#798DA3] text-justify '>
                        <h3 className='lg:text-2xl'>Distribución Inicial:</h3>
                        <ul>
                            <li>
                                *52.5% para preventa.
                            </li>
                            <li>
                                *20% para un fondo de rescate y cuidado.
                            </li>
                            <li>
                                *2.5% para el equipo fundador y gastos operativos.
                            </li>
                            <li>
                                *25% para venta pública.
                            </li>
                        </ul>
                        <h3 className='lg:text-2xl'>Quema de Tokens:</h3>
                        <p>
                            5% de los tokens se quemarán trimestralmente para fomentar la escasez y la sostenibilidad.
                        </p>
                        <h3 className='lg:text-2xl'>Programas de Staking:</h3>
                        <p>
                            Incentivos para los titulares de tokens que participen en la seguridad y estabilidad de la red.
                        </p>
                    </div>
                </div>

            </div>
            <div className="flex flex-col my-24 items-center lg:w-1/2 text-white">
                <p className="text-lg text-center mb-4">Si deseas más información sobre RenaceCoin, puedes descargar nuestro white paper.</p>
                <a
                    href={PDF} // Cambiado de 'src' a 'href'  
                    download
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Descargar White Paper
                </a>
            </div>
        </div>
    )
};

export default TokEconomics;