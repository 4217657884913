import React from 'react';
// import logo from '../image/Logo.png';

const About = () => {
    return (
        <div className='px-4 flex flex-col gap-8 justify-center items-center lg:px-60'>
            <div className='w-full flex flex-col items-center gap-4'>
                <h2 className='text-xl flex justify-center lg:justify-start lg:text-5xl text-white uppercase' >
                    QUE ES <span className='text-amber-400 px-4'>RenaceCoin</span> RNC
                </h2>
                <p className='text-white text-xl text-justify'>
                    RenaceCoin (RNC) es un token basado en tecnología blockchain que busca financiar y apoyar iniciativas dedicadas al rescate, cuidado y reubicación de mascotas en situación de calle Con una estructura de tokenomics diseñada para maximizar el impacto social, cada token representa una oportunidad para hacer una diferencia real en la vida de los animales necesitados.
                </p>
                <p className='text-white text-xl text-justify'>
                    con RenaceCoin, te conviertes en parte de una fuerza poderosa que está marcando una diferencia real tanto en las vidas humanas y de los animales necesitados. No solo estás invirtiendo en tu propio futuro financiero, sino que también estás invirtiendo en un futuro mejor para todas las mascotas. Renacecoin es más que una inversión; es un catalizador para el cambio social. Al unirnos, podemos crear un mundo donde la prosperidad y la compasión van de la mano, transformando sueños en realidad y dejando un legado de esperanza y empoderamiento para las generaciones venideras.
                </p>
            </div>
            <div className="w-full flex flex-col gap-4  text-white">
                <h2 className="text-xl flex justify-center lg:justify-start lg:text-3xl uppercase">
                    Beneficios de invertir en RenaceCoin:
                </h2>
                <ul className="list-disc list-inside text-white text-xl text-justify">
                    <li>Impacto social tangible</li>
                    <li>Inversión con conciencia</li>
                    <li>Apoyo directo a las organizaciones benéficas</li>
                    <li>Potencial de crecimiento financiero</li>
                    <li>Estructura de tokenomics optimizada</li>
                    <li>Comunidad de apoyo</li>
                    <li>Potencial de inversión</li>
                </ul>
                <h2 className="text-xl flex justify-center lg:justify-start lg:text-3xl uppercase">
                    Características clave de RenaceCoin:
                </h2>
                <ul className="list-disc list-inside text-white text-xl text-justify">
                    <li>Tecnología blockchain</li>
                    <li>Cadena de bloques pública</li>
                    <li>Escalabilidad</li>
                    <li>Interoperabilidad</li>
                    <li>Gobierno descentralizado</li>
                    <li>Soporte de la comunidad</li>
                </ul>
            </div>
        </div>
    )
};

export default About;