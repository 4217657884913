import React from 'react';
import Banner1 from '../image/Banner1.png'
import Banner2 from '../image/Banner2.png'
import Banner3 from '../image/Banner3.png'



const BuyToken = () => {
    return (
        <div className='flex flex-col gap-8 lg:my-16'>
            <div className='px-4 flex flex-col items-center gap-8 lg:px-60'>
                <div className='w-full flex flex-col gap-8 items-center'>
                    <text className='text-xl font-bold lg:text-3xl text-white uppercase' >
                        Compra Tus <span className='text-amber-400 lg:px-4'>Rnc</span>
                    </text>
                    <text className='text-white text-ms text-center'>
                        Antes de comprar RenaceCoin, necesitarás una billetera de criptomonedas que sea compatible con el token. Puedes elegir entre billeteras de software (como MetaMask o Trust Wallet) o billeteras de hardware (como Ledger o Trezor) para mayor seguridad.
                    </text>
                </div>
                <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
                    <div className='flex flex-col border border-[#414244] rounded-2xl p-4'>
                        <img src={Banner3} alt="Logo" className="h-20 rounded-xl" />
                        <text className='text-xl font-bold text-white'>Pancakeswap</text>
                    </div>
                    <div className='flex flex-col border border-[#414244] rounded-2xl p-4'>
                        <img src={Banner2} alt="Logo" className="h-20 rounded-xl" />
                        <text className='text-xl font-bold text-white'>MetaMask</text>
                    </div>
                    <div className='flex flex-col border border-[#414244] rounded-2xl p-4'>
                        <img src={Banner1} alt="Logo" className="h-20 rounded-xl" />
                        <text className='text-xl font-bold text-white'>Binance</text>
                    </div>
                </div>
            </div>
            {/* <div className='flex flex-col items-center gap-8 p-4'>
                <text className='text-5xl text-amber-400'>Exchanges</text>
                <div className='flex flex-col items-center border border-[#414244] p-6 rounded-2xl gap-4'>
                    <img src={icon4} alt='logo' className='h-20 ' />
                    <button
                        className='bg-amber-400 py-2 px-4 rounded-lg lg:block font-bold lg:w-2/3'
                        onClick={() => window.location.href = 'https://preventa.renacecoin.org/'}
                        rel="noopener noreferrer"
                    >
                        Comprar Ahora
                    </button>
                </div>
            </div> */}
        </div>
    )
};

export default BuyToken;