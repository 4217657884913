import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RoadmapCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
    };

    const roadmapItems = [
        {
            title: 'Fase 1: Fundación y Lanzamiento (0-6 meses)',
            description: 'Creación del equipo y desarrollo del whitepaper, Lanzamiento de la preventa y distribución inicial, Listado en intercambios de criptomonedas.',
        },
        {
            title: 'Fase 2: Expansión y Consolidación (6-12 meses)',
            description: 'Desarrollo de programas de recompensas y staking, Establecimiento de colaboraciones con organizaciones de rescate, Implementación de campañas educativas sobre bienestar animal.',
        },
        {
            title: 'Fase 3: Crecimiento Sostenible (12 meses en adelante)',
            description: 'Integración con plataformas de adopción de mascotas, Evaluación continua de la sostenibilidad del ecosistema, Expansión global y colaboración con organizaciones internacionales.',
        },
    ];

    return (
        <div className="max-w-4xl mx-auto">
            <Slider {...settings}>
                {roadmapItems.map((item, index) => (
                    <div className='p-4'>
                        <h2 className="text-2xl font-bold mb-2 text-center text-white">{item.title}</h2>
                        <div key={index} className="flex items-center p-6 h-72 border border-[#414244] rounded-3xl shadow-lg">
                            <p className="text-lg text-center align-middle text-white">{item.description}</p>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default RoadmapCarousel;