import React from 'react';
import logo from '../image/Logo.png';

const CustomPieChart = () => {
    const data = [15, 30, 15, 15, 25]; // Datos para las porciones  
    const porcentaje = [2.5, 52.5, 20, 5, 25];
    const labels = ['operaciones', 'Preventa', 'Rescate', 'Sostenibilidad', 'Venta Pública'];
    const colors = ['#be123c', '#0891b2', '#0284c7', '#0c4a6e', '#fb923c'];
    const radii = [0.85, 0.95, 1, 0.85, 1]; // Radios diferentes para cada segmento  

    const createArc = (startAngle, endAngle, radius) => {
        const x1 = radius * Math.cos(startAngle);
        const y1 = radius * Math.sin(startAngle);
        const x2 = radius * Math.cos(endAngle);
        const y2 = radius * Math.sin(endAngle);
        const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0;

        return `M 0 0 L ${x1} ${y1} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`;
    };

    let cumulativeAngle = 0;
    const total = data.reduce((a, b) => a + b, 0);

    return (
        <div>
            <div className=' lg:hidden'>
                <svg width="300" height="300" viewBox="-1 -1 2 2">
                    {data.map((value, index) => {
                        const startAngle = cumulativeAngle;
                        const endAngle = cumulativeAngle + (value / total) * 2 * Math.PI;
                        cumulativeAngle = endAngle;
                        const radius = radii[index] || 0.5; // Radio específico de cada segmento  

                        // Calcular la posición del texto  
                        const textAngle = startAngle + (endAngle - startAngle) / 2;
                        const textX = (radius - 0.25) * Math.cos(textAngle); // Ajustar la posición del texto  
                        const textY = (radius - 0.3) * Math.sin(textAngle); // Ajustar la posición del texto  

                        return (
                            <g key={index}>
                                <path
                                    d={createArc(startAngle, endAngle, radius)}
                                    fill={colors[index]}
                                    stroke="black" // Color del borde  
                                    strokeWidth="0.02" // Grosor del borde  
                                />
                                <text
                                    x={textX}
                                    y={(textY) - (0.08)}
                                    textAnchor="middle"
                                    fill="white" // Color del texto  
                                    fontSize="0.1" // Tamaño de la fuente  
                                    fontWeight="bold"
                                >
                                    {porcentaje[index]}%
                                </text>
                                <text
                                    x={textX}
                                    y={textY}
                                    textAnchor="middle"
                                    fill="white" // Color del texto  
                                    fontSize="0.07" // Tamaño de la fuente  
                                    fontWeight="bold"
                                >
                                    {labels[index]}
                                </text>
                            </g>
                        );
                    })}
                    <image
                        href={logo} // Rutas relativas del logo  
                        x="-0.35"
                        y="-0.35"
                        width="0.70"
                        height="0.70"
                        preserveAspectRatio="xMidYMid meet"
                    />
                </svg>
            </div>
            <div className='hidden lg:block'>
                <svg width="500" height="500" viewBox="-1 -1 2 2">
                    {data.map((value, index) => {
                        const startAngle = cumulativeAngle;
                        const endAngle = cumulativeAngle + (value / total) * 2 * Math.PI;
                        cumulativeAngle = endAngle;
                        const radius = radii[index] || 0.5; // Radio específico de cada segmento  

                        // Calcular la posición del texto  
                        const textAngle = startAngle + (endAngle - startAngle) / 2;
                        const textX = (radius - 0.25) * Math.cos(textAngle); // Ajustar la posición del texto  
                        const textY = (radius - 0.3) * Math.sin(textAngle); // Ajustar la posición del texto  

                        return (
                            <g key={index}>
                                <path
                                    d={createArc(startAngle, endAngle, radius)}
                                    fill={colors[index]}
                                    stroke="black" // Color del borde  
                                    strokeWidth="0.02" // Grosor del borde  
                                />
                                <text
                                    x={textX}
                                    y={(textY) - (0.08)}
                                    textAnchor="middle"
                                    fill="white" // Color del texto  
                                    fontSize="0.1" // Tamaño de la fuente  
                                    fontWeight="bold"
                                >
                                    {porcentaje[index]}%
                                </text>
                                <text
                                    x={textX}
                                    y={textY}
                                    textAnchor="middle"
                                    fill="white" // Color del texto  
                                    fontSize="0.07" // Tamaño de la fuente  
                                    fontWeight="bold"
                                >
                                    {labels[index]}
                                </text>
                            </g>
                        );
                    })}
                    <image
                        href={logo} // Rutas relativas del logo  
                        x="-0.35"
                        y="-0.35"
                        width="0.70"
                        height="0.70"
                        preserveAspectRatio="xMidYMid meet"
                    />
                </svg>
            </div>
        </div>
    );
};

export default CustomPieChart;