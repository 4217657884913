import React, { useEffect, useState } from 'react';
import logo from '../image/Logo.png';

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setIsScrolled(window.scrollY > 0);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <header className={`fixed top-0 left-0 w-full p-4 transition-all duration-300 ${isScrolled ? 'bg-white backdrop-blur-lg' : 'bg-[#131A25] '}`}>
            <div className="container mx-auto flex justify-between lg:items-center items-start px-4">
                <img src={logo} alt="Logo" className="h-20" />

                <button onClick={toggleMenu} className="md:hidden focus:outline-none">
                    <svg
                        className="w-6 h-6 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        {isMenuOpen ? (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        ) : (
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        )}
                    </svg>
                </button>
                {/* Menú de navegación */}
                <nav className={`md:flex ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
                    <ul className="flex flex-col items-center text-white md:flex-row space-y-2 md:space-y-0 md:space-x-4 uppercase">
                        <li>
                            <button onClick={() => scrollToSection('articulo1')} className="hover:text-amber-500 uppercase font-bold">Inicio</button>
                        </li>

                        <li>
                            <button onClick={() => scrollToSection('articulo2')} className="hover:text-amber-500 uppercase font-bold">Acerca de RNC</button>
                        </li>
                        <li>
                            <button onClick={() => scrollToSection('articulo3')} className="hover:text-amber-500 uppercase font-bold">Vision</button>
                        </li>
                        <li>
                            <button onClick={() => scrollToSection('articulo6')} className="hover:text-amber-500 uppercase font-bold">white Paper</button>
                        </li>
                        <li>
                            <a href="https://preventa.renacecoin.org/" className="hover:text-amber-500 font-bold" target="_blank" rel="noopener noreferrer">
                                Preventa
                            </a>
                        </li>
                        <li>
                            <a href="https://airdrop.renacecoin.org/" className="hover:text-amber-500 font-bold" target="_blank" rel="noopener noreferrer">
                                airdrop
                            </a>
                        </li>
                        <button onClick={() => scrollToSection('articulo5')} className='bg-amber-400 py-2 px-4 rounded-lg lg:hidden font-bold'>Comprar Ahora</button>
                    </ul>
                </nav>
                <button onClick={() => scrollToSection('articulo5')} className='hidden bg-amber-400 py-2 px-4 rounded-lg lg:block font-bold'>Comprar Ahora</button>
            </div>
        </header>
    );
};

export default Header;