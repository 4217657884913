import React from "react";

const FAQs = () => {

    return (
        <section className="flex justify-center py-10 px-4 ">
            <div className="flex flex-col gap-4 items-center lg:w-1/2 text-white">
                <h2 className="text-xl flex justify-center lg:justify-start lg:text-3xl uppercase">
                    PREGUNTAS FRECUENTES SOBRE RENACECOIN
                </h2>
                <div className="flex flex-col gap-4 text-white text-xl ">
                    <h3 className="font-bold">¿Qué es RenaceCoin?</h3>
                    <p>RenaceCoin es una criptomoneda descentralizada que tiene como objetivo promover la prosperidad y la compasión para así crear un impacto social.</p>

                    <h3 className="font-bold">¿Cuál es el objetivo de RenaceCoin?</h3>
                    <p>El objetivo de RenaceCoin es crear una economía creativa en la cual no solo busca mitigar los desafíos, sino también inspirar un cambio duradero en la forma en que la sociedad percibe y aborda problemas en nuestra sociedad.</p>

                    <h3 className="font-bold">¿Cómo funciona RenaceCoin?</h3>
                    <p>RenaceCoin utiliza la tecnología blockchain para crear un registro seguro e inmutable de transacciones. Los usuarios pueden enviar y recibir RenaceCoin a través de billeteras digitales.</p>

                    <h3 className="font-bold">¿Cuál es el suministro total de RenaceCoin?</h3>
                    <p>El suministro total de RenaceCoin es de 200 millones de tokens.</p>

                    <h3 className="font-bold">¿Cómo puedo obtener RenaceCoin?</h3>
                    <p>Puedes obtener RenaceCoin comprándolo en intercambios descentralizados de criptomonedas como PancakeSwap o participando en programas de recompensas de la comunidad.</p>

                    <h3 className="font-bold">¿RenaceCoin es una inversión segura?</h3>
                    <p>Como con cualquier inversión, el valor de RenaceCoin puede fluctuar. Es importante investigar y comprender los riesgos antes de invertir.</p>

                    <h3 className="font-bold">¿Quién está detrás de RenaceCoin?</h3>
                    <p>RenaceCoin es un proyecto de código abierto mantenido por una comunidad de desarrolladores y entusiastas.</p>

                    <h3 className="font-bold">¿Cómo puedo unirme a la comunidad de RenaceCoin?</h3>
                    <p>Puedes unirte a la comunidad de RenaceCoin a través de canales de redes sociales, foros y el sitio web oficial.</p>

                    <h3 className="font-bold">¿Dónde puedo obtener más información sobre RenaceCoin?</h3>
                    <p>Puedes encontrar más información sobre RenaceCoin en el libro blanco, el sitio web oficial y los canales de redes sociales.</p>
                </div>
            </div>
        </section>
    );
};

export default FAQs;