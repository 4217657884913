import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faTelegram,
    faLinkedin,
    faDiscord,
    faGithub,
    faYoutube,
    faTiktok
} from '@fortawesome/free-brands-svg-icons';

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'; // Importa faEnvelope desde los íconos sólidos



const ContactUs = () => {
    return (
        <section className="text-white py-10 px-4">
            <h2 className="text-3xl text-center mb-6">Contáctenos en Redes Sociales</h2>
            <div className="max-w-lg mx-auto border border-[#414244] rounded-2xl p-20 ">
                <p className="text-gray-300 mb-4">Síguenos en nuestras redes sociales para mantenerte actualizado:</p>
                <ul className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                    <li>
                        <a href="https://www.facebook.com/profile.php?id=61555617128819" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                            <FontAwesomeIcon icon={faFacebook} /> Facebook
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/RenaceCoin" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                            <FontAwesomeIcon icon={faTwitter} /> Twitter
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/renacecoin/" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                            <FontAwesomeIcon icon={faInstagram} /> Instagram
                        </a>
                    </li>
                    <li>
                        <a href="https://t.me/+b5DLKBZUA-wxYTkx" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                            <FontAwesomeIcon icon={faTelegram} /> Telegram
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/renacecoin-rnc-3aba3b2b2/" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                            <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                        </a>
                    </li>
                    <li>
                        <a href="https://discord.gg/pKM9kjTy" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                            <FontAwesomeIcon icon={faDiscord} /> Discord
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/RENACECOIN/tokenrenacecoin.sol.git" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                            <FontAwesomeIcon icon={faGithub} /> GitHub
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@RenaceCoin" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                            <FontAwesomeIcon icon={faYoutube} /> YouTube
                        </a>
                    </li>
                    <li>
                        <a href="https://www.tiktok.com/@renacecoin?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                            <FontAwesomeIcon icon={faTiktok} /> TikTok
                        </a>
                    </li>
                    <li>
                        <a href="mailto:rnc@renacecoin.org" target="_blank" rel="noopener noreferrer" className="hover:text-amber-500">
                            <FontAwesomeIcon icon={faEnvelope} /> Email
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default ContactUs;